/* Basic Reset */
body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    /* line-height: 1.6; */
    color: #333;
}

.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {
    h1 {
        font-size: 1.25rem;
    }
}
