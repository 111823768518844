/* Style for the container to align cards horizontally */
.card-container {
    display: flex;                /* Use Flexbox for horizontal alignment */
    flex-wrap: wrap;              /* Allow wrapping to the next line if needed */
    justify-content: center;      /* Center the cards horizontally */
    padding: 10px;                /* Optional padding around the container */
    gap: 1rem;                    /* Space between cards */
}

/* Style for individual cards */
.card {
    border: 1px solid #ddd;        /* Border around card */
    border-radius: 8px;            /* Rounded corners */
    overflow: hidden;              /* Prevents overflow of content */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;                 /* Flexbox for proper alignment */
    flex-direction: column;        /* Arrange content vertically */
    width: 18rem;                  /* Fixed width for cards */
    margin: 10px;                  /* Margin between cards */
}

/* Image styles */
.card-img-top {
    width: auto;                   /* Ensures image fills the card's width */
    height: auto;                  /* Allow height to adjust for full visibility */
    object-fit: contain;           /* Ensures the entire image fits within the container */
    border-radius: 5px 5px 0 0;    /* Optional: Rounded top corners for the image */
    background-color: #f8f9fa;     /* Light background for empty spaces around the image */
}

/* Body of the card */
.card-body {
    padding: 1rem;                 /* Padding for content inside the card */
    flex: 1;                       /* Allows the body to grow */
}

/* Title styles */
.card-title {
    font-size: 1.25rem;            /* Size of the card title */
    font-weight: bold;
}

/* Text styles */
.card-text {
    font-size: 0.875rem;           /* Smaller font for card text */
}

/* Button styles */
.btn-primary {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
}
